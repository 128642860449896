.course_image{
  background-position: top center !important;
}
  
  @media (max-width: 768px) { /* For tablet and smaller screens */
    .button-container {
      gap: 1rem; /* Add space between buttons if needed */
    }
    .hero-container {
        flex-direction: column !important; /* Ensure hero content stays in a row */
    }
    .font-style{
        font-size: 1.5rem !important;
    }
  }

 /* BlogDetails.css */

/* Course card styles */
.course {
  transition: transform 0.3s ease-in-out;
  background: rgba(255, 255, 255, 0.9); /* Slightly transparent background for readability */
}

.course:hover {
  transform: scale(1.05);
}

.course .tt {
  padding: 2rem; /* Adjust padding as needed */
  border-radius: 12px;
}


.BUTTON:hover{
  background-color: white;

}


.course .category span {
  font-weight: bold;
}

.course .btn-primary,
.course .btn-secondary {
  transition: background-color 0.3s ease-in-out;
}

.course .btn-primary:hover {
  background-color: #0056b3;
}

.course .btn-secondary:hover {
  background-color: white;
}

.button-container {
  border-top: 1px solid #ddd;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .course .tt {
    padding: 1rem;
  }

  .course .tt h3 {
    font-size: 1.5rem;
  }
}



.hover-effect {
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for transform and shadow */
  }
  
  .hover-effect:hover {
    transform: translateY(-10px); /* Move the element up */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow effect */
  }

  

 /* Add this CSS to your stylesheet */

 .ftco-section {
    background: #f8f9fa; /* Light background color for section */
    padding-top: 60px; /* Space above */
    padding-bottom: 60px; /* Space below */
  }
  
  .hero-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .hero-image img {
    width: 100%;
    max-height: 300px; /* Adjust the height as needed */
    object-fit: cover; /* Ensures the image covers the area */
  }
  
  .hero-text {
    flex: 1;
  }
  
  .hero-text h1 {
    font-size: 2.5rem; /* Adjust font size */
  }
  
  
  
  h2.display-5 {
    font-size: 1.75rem;
    color: #007bff; /* Primary color for subheading */
  }
  
  p.font-weight-bold {
    font-weight: bold;
  }

.ftco-section {
    background: #f8f9fa; /* Light background color */
    padding: 60px 0; /* Adjust top and bottom padding */
  }
  
  .ftco-section h1,
  .ftco-section h2 {
    margin-bottom: 20px;
  }
  
  .ftco-section h1 {
    font-size: 2.5rem; /* Adjust font size for h1 */
  }
  
  .ftco-section h2 {
    font-size: 1.75rem; /* Adjust font size for h2 */
  }
  
  .ftco-section p.lead {
    font-size: 1.125rem; /* Adjust font size for paragraph */
    color: #6c757d; /* Muted text color */
  }
  
  .feature-box {
    background: #fff; /* White background */
    border: 1px solid #ddd; /* Light border */
    border-radius: 8px; /* Rounded corners */
    padding: 20px; /* Padding inside the box */
    text-align: center; /* Center-align text */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition */
    height: 100%; /* Make all feature boxes stretch to the full height of their container */
  }
  
  .feature-box:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); 
  }
  
  .feature-icon {
    font-size: 70px;
    color: #007bff !important; 
  }
  
  .feature-title {
    font-size: 20px; 
    margin-top: 10px; 
    font-weight: bold;
  }
  
  .feature-text {
    font-size: 1rem; 
    color: #495057;
  }
  .why-choose-us>p{
    color: white !important;
  }

  .why-choose-us {
    background: linear-gradient( rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),url(../../Common/assets/images/traini.png);
    padding: 30px; /* Padding inside the section */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    margin-top: 20px;
    background-position: center ;
  }
  
  .why-choose-us-title {
    font-size: 2rem; /* Adjust font size for the title */
    color: black; /* Blue color for the title */
    border-bottom: 2px solid whitesmoke; /* Blue underline */
    display: inline-block; /* Inline block for underline */
    padding-bottom: 10px; /* Space below the title */
    margin-bottom: 20px; /* Space below the title */
    font-weight: bold;
  }
  
  .about-course{
    text-align: left;
    font-size: 20px;

  }

  .preparation-point {
    background-color: #f8f9fa; /* Light background for each point */
    padding: 15px;
    border-radius: 8px;
    display: flex;
    align-items: center;
   
  }
  

  
  .preparation-icon {
    color: #28a745; /* Green color for checkmark icon */
    font-size: 1.5rem;
    margin-right: 15px;
  }
  
  .preparation-text {
    font-size: 1.1rem;
    color: #495057;
  }
  @media (max-width: 1024px) {
    .coursepage{
      gap: 0px !important;
    }
  }
  /* Mobile responsiveness */
  @media (max-width: 768px) {
    h1 {
      font-size: 2rem;
    }
  
    .preparation-text {
      font-size: 1rem;
     
    }
  
    .coursepage{
      display: flex !important;
      flex-direction: column !important;
    }
    .faq {
      position: relative !important;
      width: 100% !important;
      padding: 10px;
      margin-bottom: 50px !important;
    }
    .packagemargin{
      margin: 0px !important;
    }
    .packagemargin{
      display: grid;
      grid-template-columns: 48% 48% !important;
      gap: 20px;
    }

  }

  @media (max-width: 600px) {
    .info {
      font-size: 14px; /* Adjust font size for smaller screens */
      top: 10px; /* Adjust positioning if needed */
      left: 10px; /* Adjust positioning if needed */
    }
    .packagemargin{
      display: grid;
      grid-template-columns: 98% !important;
      gap: 20px;
      padding-left: 30px;
    }
  }
  
  @media (max-width: 320px) {
    .info {
      font-size: 14px; /* Adjust font size for smaller screens */
      top: 10px; /* Adjust positioning if needed */
      left: 10px; /* Adjust positioning if needed */
    }
    .packagemargin{
      display: grid;
      grid-template-columns: 98% !important;
      gap: 20px;
      padding-left: 10px;
    }
  }
  
  .main {
    padding: 10px;
}

.coursepage {
    display: grid;
    grid-template-columns: 70% 30%;
    gap: 20px;
    position: relative;
}

.faq {
    position: fixed;
    top: 111px;
    right: 0;
    width: 30%;
    background-color:white;
    padding: 20px;
    height: calc(100% - 80px);
    overflow-y: auto;
    transition: all 0.3s ease;
}
.faq.scrolled {
  position: absolute;
  width: 100%; /* Allow it to expand to the container when it scrolls */
  height: auto; /* Let the height adjust */
  top: auto;
  width: 30%;
  background-color:white;
  padding: 20px;
  height: calc(100% - 80px);
}
.faq-heading {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 20px;
  color: #007bff; /* Blue color for the title */
}

.accordion {
  margin-bottom: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}

.accordion-summary {
  background-color: #f0f0f0;
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
}

.accordion-details {
  background-color: #fff;
  padding: 15px;
  font-size: 0.9rem;
  color: #555;
}

.accordion-text {
  font-weight: bold !important;
  color: #333;
  text-align: left;
}

.accordion-icon {
  color: #333;
  margin-left: auto; 
}

.crad{
  background-color: rgba(255, 152, 56, 0.05);
  border-width: 2px 0 2px 0;
  border-color: #10007a;
  border-style: solid;
  border-radius: 20px 20px 20px 20px;
  overflow: hidden;
  transition: all 0.4s;
  box-shadow: 0px 0px 10px 5px  rgba(0, 0, 0, 0.705);
}

.crad:hover {
  cursor: pointer;
  transform: scale(1.1);
  box-shadow: 0px 0px 10px 5px  rgba(0, 0, 0, 0.705);
  background-color: rgba(124, 97, 204, 0.05);
  border: 0px solid black;
}

.package-duration {
  display: block;
  border-bottom: 2px solid #000; /* Adjust color and thickness as needed */
  padding-bottom: 5px;
}

.package-name {
  margin-top: 10px;
  margin-bottom: 10px;
}

.description-border {
  border-bottom: 2px solid #000; /* Adjust color and thickness as needed */
  padding-bottom: 5px;
  text-align: left; /* Align text to the left */
  margin-bottom: 10px; /* Adjust margin as needed */
}


.packagemargin{
  display: grid;
  grid-template-columns: 30% 30% 30%;
  gap: 20px;
}

/* From Uiverse.io by Yaya12085 */ 
.plan {
  border-radius: 16px;
  box-shadow: 0 30px 30px -25px rgba(0, 38, 255, 0.205);
  padding: 10px;
  background-color: #fff;
  color: #697e91;
  max-width: 420px;
}

.plan strong {
  font-weight: 600;
  color: #425275;
}

.plan .inner {
  align-items: center;
  padding: 20px;
  padding-top: 40px;
  background-color: #ecf0ff;
  border-radius: 12px;
  position: relative;
}

.plan .pricing {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #bed6fb;
  border-radius: 99em 0 0 99em;
  display: flex;
  align-items: center;
  padding: 0.625em 0.75em;
  font-size: 1.25rem;
  font-weight: 600;
  color: #425475;
}

.plan .pricing small {
  color: #707a91;
  font-size: 0.75em;
  margin-left: 0.25em;
}

.plan .title {
  margin-top: 15px;
  font-weight: 600;
  font-size: 1.25rem;
  color: #425675;
}

.plan .title + * {
  margin-top: 0.75rem;
}

.plan .info + * {
  margin-top: 1rem;
}

.plan .features {
  display: flex;
  flex-direction: column;
}

.plan .features li {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
}

.plan .features li + * {
  margin-top: 0.75rem;
}

.plan .features .icons {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color:green;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin: 5px 0px;
}

.plan .features .icons  {
  width: 14px;
  height: 14px;
}

.plan .features + * {
  margin-top: 1.25rem;
}

.plan .action {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
}

.plan .butto {
  background-color: #6558d3;
  border-radius: 6px;
  color: #fff;
  font-weight: 500;
  font-size: 1.125rem;
  text-align: center;
  border: 0;
  outline: 0;
  width: 100%;
  padding: 0.625em 0.75em;
  text-decoration: none;
}

.plan .butto:hover, .plan .butto:focus {
  background-color: #4133B7;
}
.info {
  color: orange; /* Set text color to orange */
  text-align: left; /* Align text to the left */
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
  position: absolute; /* Make the position absolute */
  top: 0; /* Align to the top */
  left:5px; /* Align to the left */
}


.enquiry-form-section {
  margin: 0 auto;
  max-width: 900px;
  padding: 40px;
  background-color: #ffffff;
  border: 2px solid #e0e0e0;
  border-radius: 20px;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.1);
}

.enquiry-form-title {
  font-family: 'Poppins', sans-serif;
  font-size: 36px;
  text-align: center;
  color: #3c3c3c;
  margin-bottom: 40px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
}

.enquiry-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-grid-item {
  margin-bottom: 20px;
}

.form-input-field {
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 10px;
  font-size: 16px;
}

.submit-button {
  padding: 12px 40px;
  font-size: 20px;
  font-weight: 600;
  background-color: #0066cc;
  color: #fff;
  border-radius: 50px;
  box-shadow: 0px 5px 15px rgba(0, 102, 204, 0.3);
}

.submit-button:hover {
  background-color: #005bb5;
}

.error-message {
  font-size: 14px;
  color: #e53935;
  margin-top: 5px;
}

@media (max-width: 768px) {
  .enquiry-form-section {
    padding: 30px;
  }

  .enquiry-form-title {
    font-size: 30px;
  }

  .submit-button {
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .enquiry-form-section {
    padding: 20px;
  }

  .enquiry-form-title {
    font-size: 24px;
  }

  .submit-button {
    font-size: 16px;
  }
}






.hero-text h1 {
  font-family: 'Poppins', sans-serif;
  font-size: 32px;
  font-weight: bold;
}

.hero-text .btn {
  padding: 10px 30px;
  font-size: 18px;
  border-radius: 50px;
  background-color: #0066cc;
  border: none;
  transition: background-color 0.3s ease;
}

.hero-text .btn:hover {
  background-color: #004d99;
}

/* Why Choose Us Section */
.why-choose-us {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 40px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  margin-bottom: 50px;
}

.why-choose-us-title {
  font-size: 28px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.lead {
  font-size: 18px;
  color: #666;
}

/* Features Section */
.feature-box {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.feature-box:hover {
  transform: translateY(-10px);
}

.feature-icon {
  color: #4caf50;
  margin-bottom: 20px;
}

.feature-title {
  font-size: 22px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.feature-text {
  font-size: 16px;
  color: #777;
}

/* Course Preparation Section */
.preparation-point {
  background-color: #eef3fa;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.preparation-icon {
  font-size: 24px;
  color: #4caf50;
  margin-right: 10px;
}

.preparation-text {
  font-size: 16px;
  color: #555;
}

/* Exam Structure Section */
.table-responsive {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

.table {
  width: 100%;
  margin-bottom: 20px;
  background-color: #ffffff;
}

.table-bordered {
  border: 2px solid #dee2e6;
}

.table-hover tbody tr:hover {
  background-color: #f5f5f5;
}

thead.thead-light th {
  background-color: #f8f9fa;
  font-size: 18px;
  font-weight: bold;
}

/* Course Packages Section */
.packagemargin {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
}

.plan {
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease;

}

.plan:hover {
  transform: translateY(-10px);
}

.inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.pricing {
  font-size: 30px;
  font-weight: bold;
  color: #0066cc;
  margin-bottom: 20px;
}

.title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

.info {
  font-size: 16px;
  color: #777;
  margin-bottom: 20px;
}

.features {
  list-style: none;
  padding: 0;
  margin: 0;
}

.features li {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #555;
  margin-bottom: 10px;
}

.features .icons {
  color: #4caf50;
  margin-right: 10px;
}

.butto {
  background-color: #0066cc;
  color: #fff;
  padding: 10px 30px;
  border-radius: 50px;
  transition: background-color 0.3s ease;
}

.butto:hover {
  background-color: #005bb5;
}

/* General Styles for Text Headers */
h1, h2 {
  font-family: 'Poppins', sans-serif;
  color: #333;
  font-weight: bold;
}

h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

h2 {
  font-size: 28px;
  margin-bottom: 15px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero-text {
    max-width: 100%;
  }

  .packagemargin {
    flex-direction: column;
    align-items: center;
  }

  .plan {
    width: 100%;
  }
}

@media (max-width: 576px) {
  h1, h2 {
    font-size: 24px !important;
  }

  .hero-container {
    height: 300px !important;
  }

  .hero-text h1 {
    font-size: 28px;
  }

  .submit-button {
    padding: 8px 25px;
    font-size: 16px;
  }
}


.heading-unique {
  position: relative;
  padding: 20px;
  margin: 20px 0;
  text-align: center;
  border-radius: 12px;
  background: linear-gradient(to right, rgb(106, 0, 255) 0%, rgb(255, 217, 0) 100%);
  color: #fff;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.heading-unique::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300%;
  height: 300%;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 1s ease-out;
  z-index: 0;
}

.heading-unique:hover::after {
  transform: translate(-50%, -50%) scale(1);
}

.heading-unique-title {
  position: relative;
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 10px;
  z-index: 1;
}

.heading-unique-subtitle {
  position: relative;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 1px;
  z-index: 1;
}


/* Heading with underline effect */
.custom-heading {
  position: relative;
  display: inline-block;
  font-size: 2.2rem; /* Slightly larger size */
  font-weight: 700; /* Bolder text */
  color: #333; /* Adjust color as needed */
  margin-bottom: 10px; /* Space below heading */
}

.custom-heading::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -5px; /* Space between text and underline */
  width: 180px; /* Wider underline */
  height: 4px; /* Thicker underline */
  background: linear-gradient(90deg, #007bff, #00c6ff); /* Gradient color */
  transform: translateX(-50%); /* Center the underline */
  border-radius: 4px; /* Rounded corners */
}

/* Form container styling */
.enquiry-form-section {
  padding: 40px 30px;
  background: linear-gradient(135deg, #f5f7fa, #e9eff1);
  border-radius: 12px;
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  max-width: 800px;
}

/* Form title */
.enquiry-form-title {
  font-size: 2.2rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 30px;
  color: #333;
  position: relative;
}

/* Title underline effect */
.enquiry-form-title::after {
  content: "";
  display: block;
  width: 100px;
  height: 4px;
  background: #007bff;
  border-radius: 2px;
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}

/* Form input fields */
.custom-input-field {
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #ddd;
  padding: 12px;
  font-size: 1rem;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

/* Focus effect for input fields */
.custom-input-field:focus {
  border-color: #007bff;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.2);
  outline: none;
}

/* Label styling */
.MuiInputLabel-root {
  color: #555;
  font-weight: 500;
  transition: color 0.3s ease;
}

/* Focus effect for label */
.MuiInputLabel-root.Mui-focused {
  color: #007bff;
}

/* Form grid item spacing */
.custom-form-grid-item {
  margin-bottom: 20px;
}

/* Select and menu styling */
.MuiSelect-root {
  background-color: #fff;
  border-radius: 6px;
}

.MuiMenuItem-root:hover {
  background-color: #f0f0f0;
}

/* Submit button styling */
.submit-button {
  background: linear-gradient(135deg, #007bff, #0056b3);
  color: #fff;
  font-size: 1.1rem;
  padding: 12px 24px;
  border-radius: 6px;
  text-transform: uppercase;
  border: none;
  transition: background 0.3s ease, transform 0.2s ease;
}

/* Button hover effect */
.submit-button:hover {
  background: linear-gradient(135deg, #0056b3, #003d80);
  transform: translateY(-2px);
}

/* Button active effect */
.submit-button:active {
  transform: translateY(1px);
}

/* Error message styling */
.error-message {
  color: #ff4d4d;
  font-size: 0.9rem;
  margin-top: 5px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .enquiry-form-section {
    padding: 20px;
  }
  .enquiry-form-title {
    font-size: 1.8rem;
  }
  .custom-input-field {
    font-size: 0.9rem;
    padding: 10px;
  }
  .submit-button {
    font-size: 1rem;
    padding: 10px 20px;
  }
}

/* Main Form Section */
.course-user-selection-form-section {
  background: linear-gradient(135deg, #1a237e, #0d47a1);
  padding: 60px 20px;
  border-radius: 25px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
  max-width: 900px;
  margin: 50px auto;
  position: relative;
  overflow: hidden;
  color: white;
  transition: all 0.3s ease-in-out;
}

.course-user-selection-form-section:before {
  content: '';
  position: absolute;
  top: -150px;
  left: -150px;
  width: 300px;
  height: 300px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  z-index: 1;
}

.course-user-selection-form-section:after {
  content: '';
  position: absolute;
  bottom: -150px;
  right: -150px;
  width: 300px;
  height: 300px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  z-index: 1;
}

.course-user-selection-form-title {
  font-size: 36px;
  font-weight: 700;
  color: #fff;
  text-align: center;
  margin-bottom: 35px;
  z-index: 2;
  position: relative;
}

/* Form Grid Item */
.course-user-selection-form-grid-item {
  padding: 10px;
}

/* Input Field Styles */
.course-user-selection-input-field .MuiOutlinedInput-root {
  border-radius: 10px;
  padding: 5px;
  transition: border-color 0.3s ease;
  background-color: rgba(255, 255, 255, 0.1); /* Slight transparency for input field */
}

/* Input Field Text and Border Color */
.course-user-selection-input-field input {
  padding: 12px;
  font-size: 16px;
  color: white; /* White text color */
}

.course-user-selection-input-field input::placeholder {
  color: #bbb;
}

.course-user-selection-input-field .MuiOutlinedInput-notchedOutline {
  border-color: white; /* White border color */
}

.course-user-selection-input-field .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: white;
}

.course-user-selection-input-field .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: white; /* White border when focused */
}

/* Input Label Color */
.course-user-selection-input-field .MuiInputLabel-outlined {
  color: white; /* White label color */
}

.course-user-selection-input-field .MuiInputLabel-outlined.Mui-focused {
  color: white; /* White label color when focused */
}

/* Dropdown */
.course-user-selection-input-field .MuiSelect-outlined {
  border-radius: 10px;
  color: white; /* White text for dropdown */
}

/* Dropdown Border Color */
.course-user-selection-input-field .MuiOutlinedInput-notchedOutline {
  border-color: white;
}

/* Button */
.course-user-selection-submit-button {
  background-color: #1976d2;
  color: white;
  padding: 14px 30px;
  font-size: 18px;
  border-radius: 10px;
  width: 100%;
  max-width: 150px;
  margin-top: 25px;
  transition: background-color 0.3s ease;
}

.course-user-selection-submit-button:hover {
  background-color: #125ca8;
}

/* Error Message */
.error-message {
  color: #d32f2f;
  font-size: 14px;
  margin-top: 5px;
}

/* Responsive Adjustments */
@media (max-width: 900px) {
  .course-user-selection-form-section {
    padding: 20px;
  }

  .course-user-selection-form-title {
    font-size: 28px;
  }

  .course-user-selection-input-field input {
    font-size: 14px;
  }

  .course-user-selection-submit-button {
    font-size: 16px;
    padding: 12px 20px;
  }
}

@media (max-width: 600px) {
  .course-user-selection-form-title {
    font-size: 24px;
  }

  .course-user-selection-submit-button {
    font-size: 14px;
    padding: 10px 18px;
  }
}


.course-user-selection {
  background-color: #ffffff; /* Clean white background */
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s, box-shadow 0.3s;
  overflow: hidden;
  position: relative;
  padding: 20px;
}

.course-user-selection:hover {
  transform: translateY(-4px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.course-title {
  font-size: 1.6rem;
  color: #2c3e50; /* Darker shade for the title */
  font-family: 'Helvetica Neue', sans-serif; /* Clean font */
}

.category-user-selection {
  background-color: #e74c3c; /* Bright accent color */
  color: white;
  border-radius: 20px;
  padding: 5px 10px;
  font-size: 0.9rem;
  font-weight: bold;
}

.text-user-selection {
  margin: 15px 0;
}

.description-user-selection {
  color: #34495e; /* Soft dark color for the description */
  line-height: 1.6;
}

.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.button-container .btn {
  margin-top: 20px;
  margin-left: 5px; /* Space between buttons */
  font-weight: bold;
}




@media (max-width: 768px) {
  .course-user-selection {
    margin: 0 10px; /* Adjust margins for smaller screens */
  }
}

@media (max-width: 576px) {
  .text-user-selection {
    padding: 1rem; /* More padding on very small screens */
  }
}

.course_card {
  width: 100%; /* Use full width */
  max-width: 350px; /* Set a max width for larger screens */
  margin: 20px 30px 0px 50px;
  background-color: #f4f5f2;
  text-align: center;
  border-top-left-radius: 4rem;
  border: 2px solid #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Space between header, content, and ribbon */
  height: 400px; /* Set a fixed height for uniformity */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition */
}

.course_card:hover {
  transform: translateY(-5px); /* Lift the card up slightly */
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2); /* Optional shadow for depth */
}

.course_card::before {
  content: "Course";
  color: white;
  position: absolute;
  height: 30px;
  width: 120px;
  background-color: #393e7f;
  top: 32px;
  right: -2.5px;
  clip-path: polygon(10% 0, 100% 0, 100% 100%, 0 100%);
}

.card__body {
  padding: 2rem 1.5rem;
  flex-grow: 1; /* Allow the body to take remaining space */
}

.card__title {
  font-weight: 800;
  color: #121513;
  font-size: 1.25rem;
  margin: 1rem 0; /* Adjust margins */
}

.card__paragraph {
  color: #303830;
  font-size:17px;
  margin: 1rem 0; /* Adjust margins */
}

.button-container {
  margin-top: auto; /* Push buttons to the bottom */
  display: flex;
  justify-content: space-between;
}

.card__ribbon {
  margin-top: -2.5rem;
  display: grid;
  place-items: center;
  height: 50px;
  background-color: #393e7f;
  position: relative;
  width: 110%;
  left: -5%;
  top: 10px;
  border-radius: 0 0 2rem 2rem;
}

.card__ribbon::after,
.card__ribbon::before {
  content: "";
  position: absolute;
  width: 20px;
  aspect-ratio: 1/1;
  bottom: 100%;
  z-index: -2;
  background-color: #191c39;
}

.card__ribbon::before {
  left: 0;
  transform-origin: left bottom;
  transform: rotate(45deg);
}

.card__ribbon::after {
  right: 0;
  transform-origin: right bottom;
  transform: rotate(-45deg);
}

.card__ribbon-label {
  display: block;
  width: 84px;
  aspect-ratio: 1/1;
  background-color: #fff;
  position: relative;
  transform: translateY(-50%);
  border-radius: 50%;
  border: 8px solid #393e7f;
  display: grid;
  place-items: center;
  font-weight: 900;
  line-height: 1;
  font-size: 1.5rem;
}

.card__ribbon-label::before,
.card__ribbon-label::after {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  bottom: 50%;
}

.card__ribbon-label::before {
  right: calc(100% + 4px);
  border-bottom-right-radius: 20px;
  box-shadow: 5px 5px 0 #393e7f;
}

.card__ribbon-label::after {
  left: calc(100% + 4px);
  border-bottom-left-radius: 20px;
  box-shadow: -5px 5px 0 #393e7f;
}

/* Responsive Design */
@media (max-width: 1200px) {
  .course_card {
  
  }

  .card__body {
    padding: 1.5rem; /* Adjust padding */
  }

  .card__title {
    font-size: 1.2rem; /* Adjust font size */
  }

  .card__paragraph {
    font-size: 0.9rem; /* Adjust font size */
  }
}

@media (max-width: 768px) {
  .course_cards{
    display: flex;
    flex-direction: column;
  }
  .course_card {
   height: 324px;
   max-width: 280px;
  margin: auto auto 45px auto;
  }

  .card__body {
    padding: 1rem; /* Adjust padding for smaller screens */
  }

  .card__title {
    font-size: 1.1rem; /* Adjust font size */
  }

  .card__paragraph {
    font-size: 0.8rem; /* Adjust font size */
  }

  
.card__ribbon {
  margin-top: -2.5rem;
  display: grid;
  place-items: center;
  height: 50px;
  background-color: #393e7f;
  position: relative;
  width: 110%;
  left: -5%;
  top: 22px;
  border-radius: 0 0 2rem 2rem;
}
}
