/* Layout of the rows */
.rowss {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  padding: 20px;
  background-color: #f8f9fa;
}

/* Blog Card Layout */
.blog-card {
  display: flex;
  flex-direction: row;
  padding: 20px 100px;
  border-bottom: 3px solid #d0c9c9; /* Bottom border for blog separation */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(135deg, #ffffff, #f9f9f9);  /* Subtle gradient for a soft look */
  position: relative;
  overflow: hidden;
}

.blog-card:hover {
  box-shadow: 0 12px 25px rgba(0, 0, 0, 0.2);  /* Stronger shadow on hover */
}

/* Image Container with Fixed Dimensions */
.blog-image-container {
  flex: 0 0 150px;  /* Set the width for the image */
  height: 150px;    /* Set the height for the image */
  margin-right: 20px;
  overflow: hidden;
  border-radius: 15px;  /* Rounded corners for the image */
  transition: transform 0.3s ease; /* Smooth transition on hover */
}

.blog-image {
  width: 100%;
  height: 100%;
  object-fit: contain;  /* Ensure the entire image is shown */
  border-radius: 15px;  /* Matches container border-radius */
}

.blog-card:hover .blog-image-container {
  transform: scale(1.1);  /* Zoom out the image on hover */
}

/* Blog Details Section */
.blog-details {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.blog-title {
  font-size: 24px;  /* Slightly larger font size for titles */
  font-weight: bold;
  margin-bottom: 8px;
  color: #333;
  text-transform: capitalize;
}

.blog-meta {
  color: #999;
  margin-bottom: 10px;
  font-size: 14px;
  font-style: italic;
}

.blog-description {
  font-size: 16px;
  margin-bottom: 15px;
  color: #555;
  line-height: 1.6;
}

/* Read more button */
.read-more-button {
  background-color: transparent;
  border: none;
  color: #007bff;
  font-weight: bold;
  cursor: pointer;
  align-self: flex-end; /* Align the button to the right */
}

.read-more-button:hover {
  text-decoration: underline;
}

/* Responsive Design */

/* For tablet-sized screens */
@media (max-width: 768px) {
  .blog-card {
    flex-direction: column;
    text-align: center;
    padding: 15px;
  }

  .blog-image-container {
    margin-right: 0;
    margin-bottom: 15px;
    width: 100%;
    height: 200px; /* Larger image height for tablet screens */
  }

  .read-more-button {
    align-self: center;
  }
}

/* For mobile-sized screens */
@media (max-width: 480px) {
  .blog-title {
    font-size: 20px;
  }

  .blog-meta {
    font-size: 12px;
  }

  .blog-description {
    font-size: 14px;
  }

  .blog-image-container {
    height: 180px;
  }
}

.blog_top_image{
  background-position: top center !important;
}
