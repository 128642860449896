.ftco-footer {
  position: relative;
  font-size: 16px;
  padding: 0 0 20px 0;
  z-index: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
 
  
}
.ftco-footer .ftco-footer-logo {
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
.ftco-footer .ftco-footer-widget h2 {
  font-weight: normal;
  color: #000000;
  margin-bottom: 40px;
  font-size: 18px;
  font-weight: 600;
}
.ftco-footer .ftco-footer-widget ul li a {
  color: rgba(0, 0, 0, 0.5);
}
.ftco-footer .ftco-footer-widget ul li a span {
  color: rgba(0, 0, 0, 0.1);
}
.ftco-footer .ftco-footer-widget .btn-primary {
  background: #fff !important;
  border: 2px solid #fff !important;
}
.ftco-footer .ftco-footer-widget .btn-primary:hover {
  background: #fff;
  border: 2px solid #fff !important;
}
.ftco-footer p {
  color: rgba(0, 0, 0, 0.5);
}
.ftco-footer a {
  color: rgba(0, 0, 0, 0.5);
}
.ftco-footer a:hover {
  color: #007bff;
}
.ftco-footer .ftco-heading-2 {
  font-size: 17px;
  font-weight: 400;
  color: #000000;
}

.ftco-footer-social li {
  list-style: none;
  margin: 0 10px 0 0;
  display: inline-block;
}
.ftco-footer-social li a {
    text-align: left;
  height: 40px;
  width: 40px;
  display: block;
  float: left;
  color: #007bff;
  border-radius: 50%;
  position: relative;
  background: #007bff;
}
.block-23 ul li .icon {
    width: 40px;
    font-size: 18px;
    padding-top: 2px;
  color: #007bff;
  text-align: left;

}
.ftco-footer-social li a span {
  position: absolute;
  font-size: 18px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color:#007bff;
  text-align: left;
}
.ftco-footer-social li a:hover {
  color: #007bff;
}

.footer-small-nav > li {
  display: inline-block;
}
.footer-small-nav > li a {
  margin: 0 10px 10px 0;
}
.footer-small-nav > li a:hover,
.footer-small-nav > li a:focus {
  color: #007bff;
}

.bg-bottom {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom center;
}

.media .ftco-icon {
  width: 100px;
}
.media .ftco-icon span {
  color: #007bff;
}

.ftco-media {
  background: #fff;
  border-radius: 0px;
}
.ftco-media .heading {
  font-weight: normal;
}
.ftco-media.ftco-media-shadow {
  padding: 40px;
  background: #fff;
  -webkit-box-shadow: 0 10px 50px -15px rgba(0, 0, 0, 0.3);
  box-shadow: 0 10px 50px -15px rgba(0, 0, 0, 0.3);
  -webkit-transition: 0.2s all ease;
  -o-transition: 0.2s all ease;
  transition: 0.2s all ease;
  position: relative;
  top: 0;
}
.ftco-media.ftco-media-shadow:hover,
.ftco-media.ftco-media-shadow:focus {
  top: -3px;
  -webkit-box-shadow: 0 10px 70px -15px rgba(0, 0, 0, 0.3);
  box-shadow: 0 10px 70px -15px rgba(0, 0, 0, 0.3);
}
.ftco-media .icon {
  font-size: 50px;
  display: block;
  color: #007bff;
}
.ftco-media.text-center .ftco-icon {
  margin: 0 auto;
}

.ftco-overflow-hidden {
  overflow: hidden;
}

.padding-top-bottom {
  padding-top: 120px;
  padding-bottom: 120px;
}

.ftco-footer .ftco-footer-logo {
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
.ftco-footer .ftco-footer-widget h2 {
  font-weight: normal;
  color: #55230a;
  margin-bottom: 0px;
  font-size: 18px;
  font-weight: 600;
}
.ftco-footer .ftco-footer-widget ul li a {
    text-align: left;
  color: black
}
.ftco-footer .ftco-footer-widget ul li a span {

  color: #007bff
}
.ftco-footer .ftco-footer-widget .btn-primary {
  background: #fff !important;
  border: 2px solid #fff !important;
}
.ftco-footer .ftco-footer-widget .btn-primary:hover {
  background: #fff;
  border: 2px solid #fff !important;
}
.ftco-footer p {
  color: black
}
.ftco-footer a {
  color: black
}
.ftco-footer a:hover {
  color:#007bff ;
}
.ftco-footer .ftco-heading-2 {
  font-size: 17px;
  font-weight: 400;
  color: #000000;
}

#map {
  width: 100%;
}


@media (max-width: 991.98px) {
  #map {
    height: 300px;
  }
}
@media (max-width: 768px) {
    .ftco-footer {
        position: relative;
        font-size: 16px;
        padding: 0 0 20px 0;
        z-index: 0;
        border-top: 1px solid rgba(0, 0, 0, 0.05);
        
      }

      .footer-pages{
          margin-left: 0px !important;
      }
    
  }
@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(241, 93, 48, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 30px rgba(241, 93, 48, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(241, 93, 48, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(241, 93, 48, 0.4);
    -webkit-box-shadow: 0 0 0 0 rgba(241, 93, 48, 0.4);
    box-shadow: 0 0 0 0 rgba(241, 93, 48, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 30px rgba(241, 93, 48, 0);
    -webkit-box-shadow: 0 0 0 30px rgba(241, 93, 48, 0);
    box-shadow: 0 0 0 30px rgba(241, 93, 48, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(241, 93, 48, 0);
    -webkit-box-shadow: 0 0 0 0 rgba(241, 93, 48, 0);
    box-shadow: 0 0 0 0 rgba(241, 93, 48, 0);
  }
}


.block-23 ul li .text {
    display: table-cell;
    vertical-align: top;
    color: black !important;
}


.wrapper {
  display: inline-flex;
  list-style: none;
  height: 120px;
  width: 100%;
  padding-left: 0px;
  font-family: "Poppins", sans-serif;
  justify-content: center;
  margin-bottom:0px;
}

.wrapper .icon {
  position: relative;
  background: #fff;
  border-radius: 50%;
  margin: 10px;
  width: 50px;
  height: 50px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .tooltip {
  position: absolute;
  top: 0;
  font-size: 14px;
  background: #fff;
  color: #fff;
  padding: 5px 8px;
  border-radius: 5px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .tooltip::before {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  background: #fff;
  bottom: -3px;
  left: 50%;
  transform: translate(-50%) rotate(45deg);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .icon:hover .tooltip {
  top: -45px;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.wrapper .icon:hover span,
.wrapper .icon:hover .tooltip {
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
}

.wrapper .facebook:hover,
.wrapper .facebook:hover .tooltip,
.wrapper .facebook:hover .tooltip::before {
  background: #1877f2;
  color: #fff;
}

.wrapper .twitter:hover,
.wrapper .twitter:hover .tooltip,
.wrapper .twitter:hover .tooltip::before {
  background: #1da1f2;
  color: #fff;
}

.wrapper .instagram:hover,
.wrapper .instagram:hover .tooltip,
.wrapper .instagram:hover .tooltip::before {
  background: #e4405f;
  color: #fff;
}

.wrapper .youtube:hover,
.wrapper .youtube:hover .tooltip,
.wrapper .youtube:hover .tooltip::before {
  background: #ff0000;
  color: #fff;
}

.wrapper .tiktok:hover,
.wrapper .tiktok:hover .tooltip,
.wrapper .tiktok:hover .tooltip::before {
  background: #000000;
  color: #fff;
}


.wrapper .whatsapp:hover,
.wrapper .whatsapp:hover .tooltip,
.wrapper .whatsapp:hover .tooltip::before {
  background: #25D366; /* WhatsApp's primary color */
  color: #fff;
}


.custom-headings {
  position: relative;
  display: inline-block;
  font-size: 1.5rem; /* Adjust the size as needed */
  color: #333; /* Adjust the color as needed */
}

.custom-headings::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -5px; /* Adjust this to control the space between text and underline */
  width: 40px; /* Width of the underline */
  height: 2px; /* Thickness of the underline */
  background-color: #007bff; /* Blue color */
  transform: translateX(-50%); /* Center the underline */
  border-radius: 2px; /* Optional: rounded corners for the underline */
}

@media (min-width: 601px) and (max-width: 1024px) {
  .footer-lay{
     gap: 30px;
  }
  .get-in-touch{
    margin-left: 67px;
  }
}

@media (max-width: 742px) {
  .get-in-touch{
    margin-left: 0px;
  }
}