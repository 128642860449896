.testimonials-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
  }
  
  .testimonial-card-wrapper {
    flex: 1 1 calc(33.333% - 20px);
    display: flex;
    flex-grow: 0;
    
  }
  
  .testimonial-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    display: flex;
    flex-direction: column;
    height: 100%; /* Ensure the card fills its wrapper */
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  
  .testimonial-card-header {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    width: 100%;
  }
  
  .testimonial-card-logo {
    width: 60px;
    height: auto;
    margin-right: 15px;
  }
  
  .testimonial-card-avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-right: 15px;
    object-fit: cover;
  }
  
  .testimonial-card-info {
    display: flex;
    flex-direction: column;
  }
  
  .testimonial-card-name {
    font-size: 1.1em;
    font-weight: bold;
  }
  
  .testimonial-card-text {
    font-size: 0.9em;
    color: #333;
    margin-bottom: 15px;
    text-align: left;
    flex: 1;
  }
  
  .testimonial-card-rating {
    font-size: 1.2em;
    color: #f39c12;
    margin-bottom: 15px;
  }
  
  .testimonial-card-date {
    font-size: 0.8em;
    color: #777;
  }
  
  @media (max-width: 768px) {
    .testimonial-card-wrapper {
      flex: 1 1 100%;
    }
  }
  
  @media (max-width: 576px) {
    .testimonial-card {
      padding: 15px;
    }
    .testimonial-card-logo {
      width: 50px;
    }
    .testimonial-card-avatar {
      width: 50px;
      height: 50px;
    }
    .testimonial-card-name {
      font-size: 1em;
    }
    .testimonial-card-text {
      font-size: 0.8em;
    }
    .testimonial-card-rating {
      font-size: 1em;
    }
    .testimonial-card-date {
      font-size: 0.7em;
    }
  }
  