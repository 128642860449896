.e-card {
    margin: 20px auto;
    background: transparent;
    box-shadow: 0px 8px 28px -9px rgba(0,0,0,0.45);
    position: relative;
    width: 270px;
    height: 300px;
    border-radius: 16px;
    overflow: hidden;
  }
  
  .wave {
    position: absolute;
    width: 540px;
    height: 700px;
    opacity: 0.6;
    left: 0;
    top: 0;
    margin-left: -50%;
    margin-top: -70%;
    background: linear-gradient(744deg,#af40ff,#5b42f3 60%,#00ddeb);
  }
  
  .icon {
    width: 3em;
    margin-top: -1em;
  }
  
  .infotop {
    text-align: center;
    font-size: 20px;
    position: absolute;
    top: 20px;
    left: 0;
    right: 0;
    color: rgb(255, 255, 255);
    font-weight: 600;
  }
  
  .name {
    font-size: 14px;
    font-weight: 100;
    position: relative;
    top: 1em;
    text-transform: lowercase;
  }
  
  .wave:nth-child(2),
  .wave:nth-child(3) {
    top: 210px;
  }
  
  .playing .wave {
    border-radius: 40%;
    animation: wave 3000ms infinite linear;
  }
  
  .wave {
    border-radius: 40%;
    animation: wave 55s infinite linear;
  }
  
  .playing .wave:nth-child(2) {
    animation-duration: 4000ms;
  }
  
  .wave:nth-child(2) {
    animation-duration: 50s;
  }
  
  .playing .wave:nth-child(3) {
    animation-duration: 5000ms;
  }
  
  .wave:nth-child(3) {
    animation-duration: 45s;
  }
  
  @keyframes wave {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }

  .from{
    padding: 20px 130px;
  }

  .form_input{
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    margin-top:30px;
  }
  .inputs_from{
    padding: 20px 50px;
  }


  .form_inputs{
    width: 100%;
    margin-top:30px;
    padding: 0px 50px;
  }


  @media (max-width:768px) {

    .enquiry-form-section{
      margin: 20px !important;
    }
  }

  /* Contact Section */
.contact-section {
  background: #f9f9f9;
  padding: 50px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  margin: 0 auto;
  padding: 0 15px;
}

.contact-header h2 {
  text-align: center;
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 30px;
  color: #333;
  position: relative;
  padding-bottom: 10px;
  display: inline-block;
  letter-spacing: 1px;
}

.contact-header h2::before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  width: 80px;
  height: 3px;
  background: #5b42f3;
}

.contact-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  align-items: start;
}

.contact-card {
  background: #fff;
  width: 100%;
  max-width: 360px;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  text-align: center;
  transition: all 0.4s ease;
  cursor: pointer;
}

.contact-card:hover {
  transform: translateY(-10px);
  background: linear-gradient(135deg, #5b42f3, #af40ff);
  color: #fff;
}

.contact-card:hover .info-box h4,
.contact-card:hover .info-box p,
.contact-card:hover .icon-boxs i {
  color: #fff;
}

.icon-boxs {
  font-size: 3rem;
  margin-bottom: 20px;
  color: #5b42f3;
  transition: all 0.4s ease;
}

.info-boxs h4 {
  font-size: 1.25rem;
  margin-bottom: 10px;
  color: #333;
}

.info-boxs p {
  font-size: 1rem;
  color: #666;
}

/* Responsive Design */
@media (max-width: 992px) {
  .contact-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .contact-header h2 {
    font-size: 2rem;
  }
}

@media (max-width: 768px) {
  .contact-grid {
    grid-template-columns: 1fr;
  }

  .contact-card {
    max-width: 100%;
  }
}
@media (max-width:560px){
  .contact-page{
    height: 147px !important;
  }
}

@media (max-width: 576px) {
  .contact-header h2 {
    font-size: 1.8rem;
  }

  .icon-boxs {
    font-size: 2.5rem;
  }

  .info-boxs h4 {
    font-size: 1.1rem;
  }

  .info-boxs p {
    font-size: 0.9rem;
  }
}
.contact-card {
  background: #fff;
  width: 100%;
  max-width: 360px;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  text-align: center;
  transition: all 0.4s ease;
  cursor: pointer;
  min-height: 270px; /* Ensures all cards have the same height */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.info-box {
  flex-grow: 1; /* Makes sure the content stretches evenly */
}


/* Section container */
.contact-form-section {
  padding: 40px;
  background: linear-gradient(135deg, rgba(242, 242, 242, 0.8), rgba(224, 224, 224, 0.8));

  border-radius: 12px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.3);
  margin: 0 auto;
  max-width: 800px;
  border: 1px solid #ddd;
  position: relative;
}

/* Form title */
.contact-form-title {
  font-size: 2.5rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 40px;
  color: #333;
  position: relative;
  padding-bottom: 10px;
}

/* Title underline effect */
.contact-form-title::after {
  content: "";
  display: block;
  width: 80px;
  height: 6px;
  background: #6c63ff;
  border-radius: 3px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

/* Form input fields */
.contact-form-input {
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #ccc;
  padding: 15px;
  font-size: 1rem;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

/* Focus effect for input fields */
.contact-form-input:focus {
  border-color: #6c63ff;
  box-shadow: 0 0 8px rgba(108, 99, 255, 0.4);
  outline: none;
}

/* Label styling */
.MuiInputLabel-root {
  color: #777;
  font-weight: 500;
  transition: color 0.3s ease;
}

/* Focus effect for label */
.MuiInputLabel-root.Mui-focused {
  color: #6c63ff;
}

/* Grid item styling */
.contact-form-grid-item {
  margin-bottom: 20px;
}

/* Button styling */
.contact-form-submit-button {
  background: linear-gradient(135deg, #167ce9, #167ce9);
  color: #fff;
  font-size: 1.1rem;
  padding: 14px 28px;
  border-radius: 8px;
  border: none;
  transition: background 0.3s ease, transform 0.3s ease;
  cursor: pointer;
}

/* Button hover effect */
.contact-form-submit-button:hover {
  background: linear-gradient(135deg, #167ce9, #167ce9);

}

/* Button active effect */
.contact-form-submit-button:active {
  transform: translateY(1px);
}

/* Error message styling */
.MuiFormHelperText-root {
  color: #f44336;
  font-size: 0.9rem;
  margin-top: 5px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .contact-form-section {
    padding: 20px;
    background-size: cover; /* Ensure background scales properly */
  }
  .contact-form-title {
    font-size: 2rem;
    margin-bottom: 30px;
  }
  .contact-form-input {
    font-size: 0.9rem;
    padding: 12px;
  }
  .contact-form-submit-button {
    font-size: 1rem;
    padding: 10px 20px;
  }
}

.form-fileds .contact-form-input{

}

.form-group{
  box-shadow: inset 8px 8px 8px #ecf0fe,inset -8px -8px;
}