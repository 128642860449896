/* BlogDetails.css */

/* Blog Details */
.blogdetails-container {
    padding: 40px;
  }
  .blogdetails-image {
    margin-bottom: 20px;
  }
  .blogdetails-image img {
    width: 100%;
    height: 400px; /* Adjusted height for a more impactful image */
    object-fit: cover;
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  }
  .blogdetails-title {
    font-size: 2.5rem;
    margin-top: 20px;
    font-weight: 700;
    color: #333;
  }
  .blogdetails-date-location {
    margin: 15px 0;
    color: #666;
  }
  .blogdetails-description p {
    line-height: 1.8;
    color: #444;
  }
  
  /* Sidebar */
  .sidebar {
    background: #fff;
    padding: 20px; /* Padding for the sidebar */
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
  .sidebar-title {
    font-size: 1.8rem;
    margin-bottom: 20px;
    color: #333;
  }
  .sidebar-blog {
    display: flex;
    margin-bottom: 20px;
    align-items: flex-start;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
  }
  .sidebar-blog-image {
    width: 120px; /* Adjusted width for better visual balance */
    height: 100px;
    object-fit: cover;
    border-radius: 8px;
    margin-right: 15px;
  }
  .sidebar-blog-content {
    flex: 1;
  }
  .sidebar-blog-location {
    color: #888;
    margin-bottom: 5px;
  }
  .sidebar-blog-description {
    color: #444;
    margin-bottom: 5px;
  }
  .sidebar-blog-date {
    color: #666;
    margin-bottom: 10px;
  }
  .sidebar-blog-link {
    color: #007bff;
    text-decoration: none;
  }
  .sidebar-blog-link:hover {
    text-decoration: underline;
  }

  @media (max-width:1024px) {
    .yes,.no{
      max-width: 100% !important;
      flex:0 0 100% !important;
    }
    
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .hero-wrap {
      height: 300px !important;
    }
    .slider-text{
      height: 240px !important;
    }
    .hero-title {
      font-size: 2.2rem;
    }
    .blogdetails-image img {
      height: 250px;
    }
    .sidebar-blog {
      flex-direction: column;
      align-items: flex-start;
    }
    .sidebar-blog-image {
      width: 100%;
      height: auto;
    }
  }
  