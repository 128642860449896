.visually-hidden{
    display: none;
}

.carousel-indicators{
    z-index: 2;
    display: none;
}

.carousel-indicators>button{
    margin-right: 5px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 0px solid white;

}
    .quotes_image {
      background-position:center right;
     
    }
    @media (max-width: 1200px) and (min-width: 992px) {
      .quotes_image {
        background-position: right ;
      }
    }       

    .product-item {
        position: relative; /* Ensure that the product-overlay is positioned correctly */
        overflow: hidden; /* Prevent any overflow issues */
    }
    

    .product-item .product-overlay {
        position: absolute;
        width: 100%;
        height: 0;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.5);
        overflow: hidden;
        opacity: 0;
        transition: height 0.5s, opacity 0.5s /* Include padding in transition */
       
    }

   

    .product-item:hover .product-overlay {
        height: 100%;
        opacity: 1;
    
     
    }


    .product-item:hover  .course_color{
              background-color: #2161a6;
             
             
    }

    .product-item:hover .button_course{
        color:  white;
        border-color: white !important;
    }

    .product-item:hover   .button_course{
      background-color: #2161a6;
    }
    .button_course:hover{
        color:  #167ce9;
        background-color: white !important;
    }
    

    .product-item:hover * {
        color: var(--light);
    }
  

  @media (max-width: 768px) {
    .quotes_image {
      background-position: center right;
    }
  }

  
  @media (max-width: 420px) {
    .quotes_image {
      background-position: right top;
      height: 400px;
      padding: 3em 0px !important;
      text-align: center;
    }

    .quotes-container{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 350px;
    }
    

  }

  .car {
    font-size: 35px !important;
  }
  @media (max-width: 991.98px) {
    .car{
      font-size: 25px !important;
    }
  }
  @media (max-width: 576px){
    .car{
      font-size: 19px !important;
    }
    .car-image{
      margin-top: 30px;
    }
  
  }
  .image-carasole{
    height: 800px;
  }
  .slider-texts {
    height:840px;
  }

  @media (min-width: 601px) and (max-width: 1024px) {
    .slider-texts {
      height: 500px !important; /* Tablet view: 500px height */
    }
    .image-carasole{
      height: 500px !important;
    }
  }
 
  @media (max-width: 600px) {
    .slider-texts {
      height: 300px !important; /* Adjust height for phone view */
    }
    .image-carasole{
      height: 300px !important;
    }
  }
  .readmore{
    text-align: left !important;
  }

  .readmore:hover{
    color: #167ce9 !important;
    
  }

  .rows{
    padding: 50px 100px;
  }
  .course_button{
    display: flex;
    padding: 0px 50px;
    justify-content: space-between;
  }

  /* Responsive design for tablets */
@media (max-width: 768px) {
  .rows {
    padding: 30px 50px; /* Reduce padding for smaller screens */
    margin-top: 20px;
  }
  .course_button {
    padding: 0px 30px;
  }
  .product-item{
    margin: 10px 0px;
    padding: 0px 10px;
  }
  .course_button {
    flex-direction: column; /* Stack buttons vertically */
    padding: 0px 20px;
  }
}

/* Responsive design for mobile devices */
@media (max-width: 480px) {
  .rows {
    padding: 20px 20px; /* Further reduce padding for mobile */
  }

  .product-item{
    margin: 10px 0px;
    padding: 0px ;
  }

}
.se {
  padding: 50px 0 380px;
}

.media {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.back_mission {
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  height: 300px;
  background-color: lightgray;
}

.mission_circle, .mission_circles, .mission_circl {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  border: 30px solid lightpink;
  background-color: lightgray;
  z-index: 10;
}

.mission_circles {
  border-color: rgb(105, 186, 212);
}

.mission_circl {
  border-color: rgb(70, 217, 210);
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.home_icons {
  font-size: 120px;
}

.mission_home {
  width: 300px;
  height: 320px;
  background-color: lightgray;
  border-bottom-left-radius: 100%;
  border-bottom-right-radius: 100%;
  box-sizing: border-box;
  text-align: center;
  justify-content: center;
  flex: 1;
  padding: 20px;
}

@media (max-width: 768px) {
  .back_mission {
    height: 250px;
  }

  .mission_circle, .mission_circles, .mission_circl {
    width: 200px;
    height: 200px;
    border: 20px solid lightpink;
  }

  .mission_circles {
    border-color: rgb(105, 186, 212);
  }

  .mission_circl {
    border-color: rgb(70, 217, 210);
  }

  .home_icons {
    font-size: 100px;
  }

  .mission_home {
    width: 200px;
    height: 490px;
  }
  .se {
    padding: 50px 0 400px;
  }
  
}

/* Phone view (480px and below) */
@media (max-width: 480px) {
  .back_mission {
    height: 300px;
  }

  .mission2{
    margin-top: 260px;
  }

  .mission_circle, .mission_circles, .mission_circl {
    width: 300px;
    height: 300px;
    border: 30px solid lightpink;
  }

  .mission_circles {
    border-color: rgb(105, 186, 212);
  }

  .mission_circl {
    border-color: rgb(70, 217, 210);
  }
  .home_icons_rows{
    display: flex;
    flex-direction: column;
  padding: 0px 35px;
  }

  .home_icons {
    font-size: 130px;
  }

  .mission_home {
    width: 300px;
    height: 330px;
  }
}
@media (max-width: 375px) {
  .home_icons_rows{
    display: flex;
    flex-direction: column;
  padding: 0px 18px;
  }
}
@media (max-width: 320px) {
  .back_mission {
    height: 270px;
  }

  .mission2{
    margin-top: 270px;
  }

  .mission_circle, .mission_circles, .mission_circl {
    width: 270px;
    height: 270px;
    border: 30px solid lightpink;
  }

  .mission_circles {
    border-color: rgb(105, 186, 212);
  }

  .mission_circl {
    border-color: rgb(70, 217, 210);
  }
  .home_icons_rows{
    display: flex;
    flex-direction: column;
  padding: 0px 3px;
  }

  .home_icons {
    font-size: 130px;
  }

  .mission_home {
    width: 270px;
    height:345px;
  }
}
.slider-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.review-card {
  text-align: center;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px 15px; /* Gap between reviews */
  position: relative;
  overflow: visible; /* Ensure content isn't cut off */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 380px; /* Set a fixed height */
}

.google-logo {
  width: 60px;
  height: auto;
  margin-bottom: 10px;
}

.review-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
}

.review-name {
  font-weight: bold;
  font-size: 16px;
  margin: 10px 10px;
}

.review-text {
  font-size: 14px;
  color: #555;
  margin-top: 10px;
}
.review-header {
  display: flex;
  margin-bottom: 10px;

}
.review-date {
  font-size: 12px;
  color: #888;
}

.rating {
  margin-top: 10px;
  font-size: 18px;
  color: #f5c518;
}

.slick-slide {
  padding: 0 10px; /* Gap between slides */
}

.review-card:hover {
  transform: scale(1.05);
  background-color: #73b5cb; /* New background color on hover */
  color: white;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  color: black;

}


/* Style for dots */
.slick-dots li button:before {
  font-size: 12px;
  color: #004d66; /* Color for inactive dots */
}

.slick-dots li.slick-active button:before {
  color: #004d66; /* Color for active dot */
}


.values-section {
  padding: 60px 20px;
  background-color: #f4f4f9;
}

.content-wrapper {
  max-width: 1200px;
  margin: 0 auto;
}

.cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
}

.card {
  background: linear-gradient(135deg, #f0f0f0 0%, #ffffff 100%);
  border: 1px solid #ddd;
  border-radius: 12px;
  padding: 20px;
  flex: 1 1 calc(33.333% - 20px);
  max-width: calc(33.333% - 20px);
  transition: transform 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.card::before {
  content: '';
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.05);
  z-index: 0;
  transition: background 0.3s ease;
}

.card:hover::before {
  background: rgba(0, 0, 0, 0.1);
}

.card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  border-color: #bbb;
}

.card-content {
  position: relative;
  z-index: 1;
}

.icon-box {
  margin-bottom: 20px;
  padding: 20px; /* Increased padding */
  border-radius: 50%;
  background: #e0f7fa;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.mission-icon {
  background-color: #e1f5fe;
}

.vision-icon {
  background-color: #e8f5e9;
}

.values-icon {
  background-color: #fce4ec;
}

.iconss {
  font-size: 3rem; /* Increased font size */
  color:#167ce9  /* Adjust color as needed */
}

.title {
  font-size: 1.75rem;
  margin-bottom: 15px;
  color: #333;
}

.description {
  font-size: 1rem;
  color: #555;
}

@media (max-width: 992px) {
  .card {
    flex: 1 1 calc(50% - 20px);
    max-width: calc(50% - 20px);
  }

  .iconss {
    font-size: 2.5rem; /* Adjust icon size for medium screens */
  }
}

@media (max-width: 768px) {
  .card {
    flex: 1 1 100%;
    max-width: 100%;
  }

  .iconss {
    font-size: 2rem; /* Adjust icon size for small screens */
  }
}

@media (max-width: 576px) {
  .values-section {
    padding: 40px 15px;
  }

  .title {
    font-size: 1.5rem;
  }

  .description {
    font-size: 0.9rem;
  }

  .iconss {
    font-size: 1.75rem; /* Adjust icon size for very small screens */
  }
}


.blog-entry {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 30px;
  transition: transform 0.3s ease;
}

.blog-entry:hover {
  transform: translateY(-10px);
}

.block-20 {
  height: 200px; /* Fixed height for the image */
  width: 100%; /* Ensure it takes full width of the parent */
  background-size: cover;
  background-position: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.texts {
  background-color: #fff;
  padding: 20px;
}

@media (max-width: 768px) {
  .col-md-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .col-md-4 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
/* Background and text color changes on hover */
.course_color {
  background-color: white; /* Default background color */
  color: black; /* Default text color */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition */
}

/* Hover state for the course_color */
.product-item:hover .course_color {
  background-color: #2161a6; /* Blue background color on hover */
  color: white; /* All text inside turns white */
}

/* Ensures all text inside course_color changes to white */
.product-item:hover .course_color h3,
.product-item:hover .course_color p,
.product-item:hover .course_color .meta div a {
  color: white; /* Set text and links inside the div to white */
}

/* Hover effect for the icon */
.product-item .product-overlay .btn-lg-square {
  background-color: white; /* White background for the icon */
  color: #2161a6; /* Blue icon color */
  border-radius: 50%; /* Circle */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition */
}

.product-item:hover .product-overlay .btn-lg-square i {
  color: #2161a6; /* Blue icon on hover */
}

.product-item:hover .product-overlay .btn-lg-square {
  background-color: white; /* White background for the icon */
  color: #2161a6; /* Ensure the icon color stays blue */
}

/* Add some hover animation for the entire product item */
.product-item:hover {
  transform: translateY(-5px); /* Slight lift on hover */
  transition: transform 0.3s ease-in-out; /* Smooth lift animation */
}

/* Hover effect for the links */
.product-item .course_color a:hover {
  text-decoration: none; /* Remove underline on hover */
  color: white; /* Keep links white on hover */
}

/* Ensure proper cursor for links */
.course_color a {
  cursor: pointer;
}

/* Hover for h3 and other text */
.product-item:hover .heading a {
  color: white; /* Ensure h3 link color is white on hover */
}

/* Responsive design adjustments */
@media (max-width: 768px) {
  .col-md-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .col-md-4 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .mdddd{
    margin-top: 20px;
  }
}

.product-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

/* Set min-height to ensure the cards have consistent height */
.blog-entry {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 450px; /* Adjust this value to control the height */
}

/* The image at the top should maintain a fixed height */
.block-20 {
  height: 200px;
  background-size: cover;
  background-position: center;
  width: 100%;
}

/* Ensure the content area fills the remaining space */
.course_color {
  flex-grow: 1;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

/* Ensure heading, paragraph, and meta information stay consistent */
.heading,
.meta,
p {
  margin: 0;
}

/* Ensure all text fits nicely and respects padding */
.heading a,
.meta div a,
p {
  color: black;
  text-decoration: none;
}

/* Hover Effects */
.product-item:hover .course_color {
  background-color: #2161a6;
  color: white;
}

/* Hover text colors */
.product-item:hover .heading a,
.product-item:hover .meta div a,
.product-item:hover p {
  color: white;
}

/* Make the icon visible on hover */
.product-item:hover .product-overlay {
  opacity: 1;
  transform: translateY(0);
}

.product-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) translateY(-20px); /* Start with the icon slightly hidden */
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.product-overlay .btn-lg-square {
  background-color: white;
  color: #2161a6;
}

.product-item:hover .product-overlay .btn-lg-square i {
  color: #2161a6;
}
.section-home{
  background: #f8f9fa;
  padding-top: 160px;
  padding-bottom: 40px;
}

.main{
  width: 100%;
  min-height: 380px;
  text-align: center;
  cursor: pointer;
  position: relative;
}

.service{
  padding: 30px;
  position: absolute;
  border-radius: 10px;
  bottom: 0;
  background-color: #fff;
  z-index: 1;
  box-shadow: 0 0 20px -15px #030380;
  transition: transform .8s;
}

.service-logo{
  width: 130px;
  height: 130px;
  border-radius: 50%;
  margin: -90px auto 0;
  background-color: #fff;
  border: 18px solid  #f8f9fa;
}

.service-icons{
  width: 50px;
  margin-top: 23px;
  font-size: 45px;
  text-align: center;
  color: #0d7ae7;
  transition: transform 1s;
}

.service h4{
  height: 35px;
  width: 80%;
  background-color: #fff;
  margin: 50px auto;
  position: relative;
}
.service h4::after{
  content: '';
  width: 40px;
  height: 30px;
  background: linear-gradient(to right,#fff,#0d7ae7);
  position: absolute;
  right: -5px;
  top: -5px;
  z-index: -1;
}
.service h4::before{
  content: '';
  width: 40px;
  height: 30px;
  background: linear-gradient(to right,#0d7ae7,#fff);
  position: absolute;
  left: -5px;
  bottom: -5px;
  z-index: -1;
}
.shadowOne{
  height: 200px;
  width: 80%;
  background: #fff;
  position: absolute;
  border-radius: 10px;
  bottom: 0;
  left: 10%;
  box-shadow: 0 0 20px -15px #030380;
 transition: transform .8s;
}
.shadowTwo{
  height: 200px;
  width: 64%;
  background: #fff;
  position: absolute;
  border-radius: 10px;
  bottom: 0;
  left: 18%;
}
.main:hover .service{
  transform: translateY(-50px);

}
.main:hover .shadowOne{
  transform: translateY(-25px);

}
.main:hover .service-icons{
  transform:rotate(360deg);

}
/* Specific styles for screens between 320px and 576px */
@media (min-width: 320px) and (max-width: 576px) {
  .main {
    min-height: 300px;
    margin-top: 65px;
  }

  .mains {
    margin-bottom: 170px;
  }
}
@media screen and (max-width: 320px) and (min-width: 320px) {
  .main{
    margin-top: 120px !important;
  }
}
/* General mobile styles for screens under 576px */
@media (max-width: 576px) {
  .main {
    min-height: 300px;
    margin-top: 66px;
  }

  .mains {
    margin-bottom: 150px;
  }

  .service-icons {
    width: 50px;
    margin-top: 14px;
    font-size: 45px;
    text-align: center;
    color: #0d7ae7;
    transition: transform 1s;
  }

  .service {
    padding: 20px;
  }

  .service-logo {
    width: 100px;
    height: 100px;
    border: 15px solid #f8f9fa;
  }

  .service h4 {
    height: 30px;
    width: 90%;
    margin: 40px auto;
  }

  .shadowOne {
    height: 150px;
    width: 90%;
    left: 5%;
  }

  .shadowTwo {
    height: 150px;
    width: 80%;
    left: 10%;
  }
}


/* Tablet styles */
@media (min-width: 577px) and (max-width: 768px) {
  

.service-home{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center !important;
}

  .main {
    min-height: 340px;
  }

  .mains {
    margin-top: 70px;
    margin-bottom: 140px;
    
  }
  .service {
    padding: 25px;
  }
.ma{
  margin-top: 70px;
}
  .service-logo {
    width: 120px;
    height: 120px;
    border: 16px solid #f8f9fa;
  }

  .service h4 {
    height: 32px;
    width: 85%;
    margin: 45px auto;
  }

  .shadowOne {
    height: 170px;
    width: 85%;
    left: 7.5%;
  }

  .shadowTwo {
    height: 170px;
    width: 70%;
    left: 15%;
  }
}
@media (min-width: 1020px) and (max-width: 1200px) {
  .service-home{
    margin-top: 160px;
  }

}

/* Desktop styles */
@media (min-width: 769px) and (max-width: 1024px) {
 
  .main {
    min-height: 360px;
    margin-top:70px ;
  }
  .ma{
    margin-top: 200px;
  }

  .service-logo {
    width: 125px;
    height: 125px;
    border: 17px solid #f8f9fa;
  }

  .service h4 {
    height: 34px;
    width: 82%;
    margin: 48px auto;
  }

  .shadowOne {
    height: 180px;
    width: 83%;
    left: 8.5%;
  }

  .shadowTwo {
    height: 180px;
    width: 68%;
    left: 16%;
  }
}
@media (max-width: 590px) {
  .home-image{
    background-image: url('../../../../public/assets/images/home12.jpg') !important;
  }
  .pte-image{
    background-image: url('../../../../public/assets/images/pte12.jpg') !important;
  }
  .osce-image{
    background-image: url('../../../../public/assets/images/osce.jpg') !important;
  }
}

.service-home{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: start;
}




.course-name {
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
  font-weight: bold;
  color: #333;
  border: 2px solid transparent;
  transition: 0.3s ease;
}

.course-name.active {
  color: #fff;
  background-color: #007bff;
  border-radius: 50px;
  border-color: #007bff;
}

.icons.green-icon {
  color: green; /* Change icon color to green */
}

.package-container {
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
  justify-content: space-between;
  padding: 40px 2em;
  gap: 2em; /* Adjust the gap between the package cards */
}

.package-home-details {
  width: 100%;
  max-width: 30%; /* Ensure a consistent width for the package cards */
  background-color: #ffffff;
  border-radius: 10px;
  text-align: center;
  padding: 2.5em 0;
  box-shadow: 0 20px 35px rgba(0,0,0,0.1);
  flex-grow: 1; /* Allow the cards to grow equally */
}

.package-title {
  width: 70%;
  padding: 0.6em 0;
  background: linear-gradient(45deg,#00cdf3,#0179e9);
  color: #ffffff;
  border-radius: 5px 35px 35px 5px;
  margin-left: -0.6em;
  font-weight: 400;
  letter-spacing: 0.15em;
}

.package-price {
  font-size: 2em !important;
  margin: 0.8em 0;
  color: #07001f;
}

.package-features {
  list-style: none;
  padding: 0;
}

.feature-item {
  display: flex;
  align-items: center; /* Align icon and text vertically */
  text-align: left; /* Align text to the left */
  width: 80%; /* Full width for feature items */
  margin: auto;
  padding-bottom: 0.4em;
  color: #000742;
}

.package-features .feature-item:not(:last-child) {
  margin-bottom: 1.8em;
  border-bottom: 1px solid #a4a5aa;
}

.feature-item .icons {
  margin-right: 10px; /* Space between icon and text */
}

/* Center the single package card */
.package-container:only-child {
  justify-content: center; /* Center single div */
}

.package-container:only-child .package-home-details {
  margin: 0 auto; /* Ensures the single card is centered horizontally */
}

.package-button {
  font-size: 1.2em;
  margin-top: 2.5em;
  width: 50%; /* Adjust button width */
  padding: 10px 0; /* Adjust button height */
  background: linear-gradient(45deg,#00cdf3,#0179e9);
  border: none;
  color: #ffffff;
  border-radius: 5px;
  cursor: pointer;
  height: 60px;
}

/* Responsive Design */

@media (max-width: 1024px) {

  .package-home-details {
    max-width: 45%; /* Adjust width for tablets */
  }
}
.ftco-navbar-light{
 z-index: 999;
}
.oi {
  color: #55230a !important;
}
.ftco-navbar-light .navbar-nav > .nav-item > .nav-link{
  color : #55230a !important;
}
@media (max-width: 768px) {
  .kk{
    padding: 0px 0px 20px 0px;
  }
  .ftco-navbar-light .navbar-nav > .nav-item > .nav-link{
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  .package-home-details {
    max-width: 100%; /* Full width for smaller screens */
  }

  .package-container {
    justify-content: center; /* Center align for smaller screens */
  }

  .package-button {
    width: 50%; /* Adjust button width for smaller screens */
  }
}

.give{
  font-size: 30px;
}
.give1{
  font-size: 25px;
}
/* For laptops and larger screens */
@media (max-width: 1200px) {
  .give {
    font-size: 28px;
  }
  .give1 {
    font-size: 23px;
  }
}

/* For tablets */
@media (max-width: 768px) {
  .give {
    font-size: 24px;
  }
  .give1 {
    font-size: 20px;
  }
}

/* For mobile phones */
@media (max-width: 480px) {
  .give {
    font-size: 20px;
  }
  .give1 {
    font-size: 18px;
  }
}



/* Zoom-in animation for images */
@keyframes zoomIn {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  100% {
    transform: scale(1.1);  /* Slight zoom */
    opacity: 1;
  }
}

.animated-img {
  animation: zoomIn 1s ease forwards;  /* Apply zoom effect */
}

/* Slide-in animation for captions */
@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.animated-caption {
  animation: slideInFromBottom 1s ease forwards;
  animation-delay: 0.5s;  /* Delay to let the image animate first */
}