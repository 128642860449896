.video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    height: 0;
    overflow: hidden;
    max-width: 100%;
    background: #000;
  }
  
  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

 
@media (max-width: 480px) {
    .videos_row {
        gap: 10px; /* Even smaller gap for very small screens */
    }
}

.MuiImageList-root {
  width: 100% !important;
  height: auto !important;
  overflow-y: hidden !important;

}
.image-item img {
  transition: transform 0.5s ease;
  transform: scale(1); /* Default scale */
}

.image-item:hover img {
  transform: scale(0.9); /* Zoom-out effect */
}

.video-thumbnail {
  cursor: pointer;
  transition: transform 0.3s ease;
}

.video-thumbnail:hover {
  transform: scale(1.05);
}

.modal-video-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-video-container iframe {
  border: none;}
