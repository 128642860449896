.dropdown-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    padding: 10px;
    border: 1px solid #ccc;
    list-style-type: none;
  }

  .nav-item:hover .dropdown-menu {
    display: block;
  }
  .uop{
    font-size: 20px;
  }

  @media (max-width: 768px) {
    .dropdown-menu {
   text-align: center;
    }
    .uop{
      font-size: 16px;
    }
    .back-to-top {
      position: fixed;
      right: 5px !important;
      bottom: 78px !important;
      z-index: 99;
    }
    
    .rounded-circle {
      height: 40px !important;
      width: 40px !important;
      border-radius: 50% !important;
  }
  }

  .back-to-top {
    position: fixed;
    right: 25px;
    bottom: 80px;
    z-index: 99;
  }

  .rounded-circle {
    height: 50px;
    width: 50px;
    border-radius: 50% !important;
}

/* Default link color */
.nav-link {
  color: #333; /* Change this to your default color */
}

/* Change color on hover */
.nav-link:hover{
  color: #007bff !important; /* Blue color on hover */
}

/* Change color when the link is active */
.nav-item.active .nav-link,
.nav-link.active {
  color: #007bff; /* Blue color when active */
}

/* Optional: Change color for dropdown items */
.dropdown-item:hover {
  background-color: #f8f9fa; /* Background color on hover */
  color: #007bff; /* Blue color on hover */
}

/* Optional: Adjust color for CTA button */
.nav-item.cta .nav-link {
  background-color: #007bff; /* Blue background for CTA */
  color: #fff; /* White text color for CTA */
  border-radius: 4px; /* Optional: rounded corners */
  padding: 10px 20px; /* Optional: padding for CTA button */
}

.logo{
  width: 323px;
  height: 70px;

}
@media (max-width: 560px) {
  .logo{
    width: 240px !important;
  }
}

@media (max-width: 768px) {
  .logo {
    width: 200px; /* Adjust width for tablet and mobile views */
    height: 66px;
  }
}
/* Common link styles (applies to all devices) */
.custom_nav-container .navbar-nav .nav-item .nav-link {
  padding: 5px 20px 0px 20px;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
  border-radius: 5px;
  text-decoration: none; /* Remove default underline */
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  position: relative; /* Required for the underline */
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

/* Underline styles */
.custom_nav-container .navbar-nav .nav-item .nav-link::after {
  content: '';
  position: absolute;
  background-color: white; /* Color of the underline */
  height: 2px; /* Thickness of the underline */
  margin: 0px 20px;
  width: 70%; /* Full width of the text */
  left: 0;
  bottom: 0px; /* Position slightly below the text */
  transform: scaleX(0); /* Start with no visible width */
  transform-origin: left; /* Start scaling from the left */
  transition: transform 0.3s ease;
}

/* Hover state: expand the underline to match content width */

/* Active link styles */
.custom_nav-container .navbar-nav .nav-item.active .nav-link {
  color: blue !important; /* Set the active link text color to blue */
}



/* For tablets */
@media (max-width: 768px) {
  .custom_nav-container .navbar-nav .nav-item .nav-link {
    font-size: 16px; /* Adjust font size for tablets */
    padding: 5px 15px;
  }

  
}

/* For mobile devices */
@media (max-width: 480px) {
  .custom_nav-container .navbar-nav .nav-item .nav-link {
    font-size: 14px; /* Adjust font size for mobile */
    padding: 5px 10px;
  }


}

