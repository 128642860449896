




.heading-section h2 {
    font-size: 30px;
    color: #333;
    text-transform: uppercase;
    letter-spacing: 2px;
    position: relative;
}

.heading-section h2::after {
    content: '';
    width: 100px;
    height: 4px;
    background-color: #007bff;
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
}

.profiles {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    flex-direction: column;
}

.profile-card {
    background-color: #f0ffe0;
    box-shadow: 10px 10px 20px #d1d1d1, -10px -10px 20px #ffffff;
    padding: 30px;

    flex: 1;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}


.profile-card img {
    border-radius: 50%;
    width: 190px;
    height: 190px;
    object-fit: cover;
    margin-bottom: 20px;
    border: 5px solid #007bff;
}

.profile-card h3 {
    font-size: 28px;
    margin-bottom: 10px;
    color: #007bff;
}

.profile-card h4 {
    font-size: 18px;
    margin-bottom: 15px;
    color: #666;
    font-style: italic;
}

.profile-card p {
    font-size: 16px;
    color: #555;
    line-height: 1.7;
    margin-bottom: 20px;
}

@media (max-width: 768px) {
    .profiles {
        flex-direction: column;
        align-items: center;
    }

    .profile-card {
        max-width: 90%;
    }
}

.about_top_image{
  background-position: bottom center !important;
}

.profile-info {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
   justify-content: space-evenly;
   width: 100%;
   padding-top: 20px;
  }
  
  .profile-image-container {
    flex-shrink: 0; /* Prevent image container from shrinking */
  }
  
  .profile-image {
    max-width: 150px; /* Adjust size as needed */
    border-radius: 8px; /* Rounded corners */
  }
  
  .profile-details {
    margin-top: 10px;
  }
  
  .profile-content p {
    margin-bottom: 10px; /* Spacing between paragraphs */
    line-height: 1.6; /* Improve readability */
  }


  
  .profile-info {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 20px; /* Space between image and content */
  }
  
  .profile-image-container {
    flex-shrink: 0; /* Prevent image container from shrinking */
  }
  
  .profile-image {
    max-width: 150px; /* Adjust size as needed */
    border-radius: 8px; /* Rounded corners */
  }
  
  .profile-details {
    margin-top: 10px;
  }
  
  .profile-content p {
    margin-bottom: 10px; /* Spacing between paragraphs */
    line-height: 1.6; /* Improve readability */
  }


  .profiles {
    display: flex;
    flex-direction: column;
   
  }

  .profile-card:nth-child(even){
    background-color: #004d66;
    color: white;
   
  }


  .profile-card:nth-child(even) .profile-info {
    flex-direction: row-reverse; /* Reverse direction for the second profile-card */
  }
  
  
  .profile-card {
    background-color: #3abfdd ;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
  }
  
  /* Flex settings for profile information */
  .profile-info {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 20px;
  }
  
  /* Profile image container and image styling */
  .profile-image-container {
    flex-shrink: 0;
  }
  
  .profile-image {
    max-width: 150px;
    border-radius: 8px;
    object-fit: cover;
  }
  
  /* Profile text and alignment */
  .profile-details {
    margin-top: 10px;
  }
  
  .profile-details h3 {
    font-size: 1.4rem;
    color: #333;
  }
  
  .profile-details h4 {
    font-size: 1.1rem;
    color: #777;
  }
  
  .profile-content {
    text-align: left;
    max-width: 600px;
  }
  
  .profile-content p {
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
  }
  
  /* Media query for responsiveness */
  @media screen and (max-width: 768px) {
    .profile-info {
      flex-direction: column !important;
      align-items: center;
    }
  
    
    
  .second{
    display: flex;
    flex-direction: column !important;
  }
  }

  .second{
    display: flex;
    flex-direction: row-reverse;
  }

  .profile-bg {
    position: relative;
    display: inline-block;
    padding: 30px;
    border-radius: 50%;
}

.profile-bg::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: -15px;
    left: -15px;
    background: white; /* Soft teal */
    border-radius: 50%;
    z-index: -1;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .img-about {
    height: 400px;
    margin-bottom: 30px;
}

.local-about{
  flex-direction: column;
}
.local-abouts{
  max-width: 100%;
}
}